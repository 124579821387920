import {Routes} from '@angular/router';
import {LoginComponent} from "./components/login/login.component";

export const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: '',
    loadChildren: () => import('./view/container/container.module').then(m => m.ContainerModule),
  },
];
