import {Component} from '@angular/core';
import {GanttModule} from '@syncfusion/ej2-angular-gantt';
import {RouterOutlet} from '@angular/router';
import {ngModule} from "./shared/primeng/primeng";
import {ConfirmationService, MessageService} from "primeng/api";
import {HeaderComponent} from "./components/layout/header/header.component";
import { L10n } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [GanttModule, RouterOutlet, ngModule, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    MessageService,
    ConfirmationService]
})
export class AppComponent {
  title = 'saas-pm-ng';

}
