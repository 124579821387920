<div class="box" (keydown.enter)="login()">
  <div class="box-form">
    <div class="box-login-tab"></div>
    <div class="box-login-title grid">
      <span class="text-cyan-900 font-bold pi pi-user flex justify-content-center mt-3 pr-0 col-6"></span>
      <span class="text-cyan-900 font-bold  mt-3 pl-0 col">登入</span>
    </div>
    <div class="box-login">
      <div class="grid mt-3">
        <div class="ml-auto mr-3">
          <p-button icon="text-cyan-900 text-xs pi pi-ellipsis-h" (click)="openLoginInfo()"
                    styleClass="p-button-sm p-button-secondary p-button-text b-form"></p-button>
        </div>
      </div>
      <form [formGroup]="login_form" id="login_form">
        <div class="grid flex justify-content-center mt-2">
          <div class="flex flex-column col-10 pt-0 gap-2">
            <h1 class="text-cyan-900 font-bold text-lg text-left mb-5">{{ title }}</h1>
            <label class="text-cyan-900">帳號</label>
            <input pInputText formControlName="user_name" id="user_name"
                   [ngClass]="{'ng-dirty':login_form.controls['user_name'].hasError('required') && login_form.controls['user_name'].touched}"/>
          </div>
          <div class="flex flex-column col-10 gap-2">
            <label class="text-cyan-900">密碼</label>
            <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                        inputStyleClass="w-full" id="password"
                        [ngClass]="{'ng-dirty':login_form.controls['password'].hasError('required') && login_form.controls['password'].touched}">
            </p-password>
          </div>
        </div>
        <div class="flex justify-content-center">
          <p-button label="登入" styleClass="flex justify-content-center align-items-center mt-4 login-btn"
                    [loading]="loading" (click)="login()"></p-button>
        </div>
<!--        <asl-google-signin-button type='standard' size='medium'-->
<!--                                  class="flex justify-content-center mb-2"></asl-google-signin-button>-->
<!--        <cool-facebook-button (click)="signInWithFB()">Login with Facebook</cool-facebook-button>-->
      </form>
    </div>
  </div>
  <div class="box-info">
    <div class="p-0 mt-1">
      <p-button icon="pi pi-times" (click)="closeLoginInfo()"
                styleClass="p-button-rounded p-button-secondary p-button-text b-info"></p-button>
    </div>
    <div class="p-0 mt-1">
      <p-button label="忘記密碼" (click)="goToPasswordReset()"
                styleClass="text-cyan-900 p-button-text mb-2 col-12"></p-button>
    </div>
    <div class="p-0 mt-1">
      <p-button label="聯絡我們" styleClass="p-button-secondary p-button-text mb-2 col-12"></p-button>
    </div>
    <div class="p-0 mt-4 flex justify-content-center">
              <span class="text-xs">未註冊帳號?
                <button class="text-button text-cyan-600 font-medium" routerLink="/register">註冊</button>
              </span>
    </div>
  </div>
</div>
<div class="info">
  <p>特色:</p>
  1. 完全原生雲服務: SaaS-Serverless架構(AWS Cloud)<br>
  2. AI Embeddings / RAG / Agents 企業模型訓練及應用設計<br>
  3. 法遵雲端服務與企業等級之資訊安全及個資法規範, 2FA驗證<br>
  4. 高性能及網路防護設計: 承載大規模跨國組織(每秒萬級之請求數)<br>
  5. 法遵上市櫃企業內稽內控制度要求(所有編修行為日誌化永久保存)<br>
  6. 敏捷群組型看板管理(同步所有使用者端更新)<br>
  7. 專案管理及甘特圖/資源圖報表(同步所有使用者端更新)<br>
  8. 個人與同事間的任務與分派與追蹤<br>
  9. BPM簽核及自動化流程管理雲服務<br>
  10. 組織及人力資源管理雲服務<br>
  11. 國際化多語系支援<br>
  12. 數據每日自動備份
</div>
<!--<p-dialog [(visible)]="isVerify" [style]="{width: '30vw'}" [modal]="true"-->
<!--          (keydown.enter)="verify()" [closable]="false"-->
<!--          transitionOptions="0ms cubic-bezier(0, 0, 0, 0)">-->
<!--  <div class="grid flex justify-content-center align-items-center" [class]="dialogClass">-->
<!--    <div class="col-12 flex justify-content-end">-->
<!--      <p-button icon="pi pi-times" (click)="closeVerify()"-->
<!--                styleClass="p-button-rounded p-button-secondary p-button-text"></p-button>-->
<!--    </div>-->
<!--    <div class="col-12 flex justify-content-center align-items-center p-3">-->
<!--      <span class="text-xl font-bold">多重驗證</span>-->
<!--    </div>-->
<!--    <div class="col-12 flex justify-content-center align-items-center">-->
<!--      <i class="bg-blue-100 border-circle w-5rem h-5rem flex justify-content-center align-items-center">-->
<!--        <i class="pi pi-lock text-blue-500 text-5xl"></i>-->
<!--      </i>-->
<!--    </div>-->
<!--    <div class="col-12 flex justify-content-center align-items-center gap-2">-->
<!--      &lt;!&ndash;<input pInputText pKeyFilter="int" formControlName="passcode"&ndash;&gt;-->
<!--      &lt;!&ndash;[ngClass]="{'ng-dirty':verify_form.controls['passcode'].hasError('required') &&&ndash;&gt;-->
<!--      &lt;!&ndash;verify_form.controls['passcode'].touched}"/>&ndash;&gt;-->
<!--      <input #input0 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[0]"-->
<!--             [ngClass]="code[0]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 0)" (paste)="onPaste($event, 0)">-->
<!--      <input #input1 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[1]"-->
<!--             [ngClass]="code[1]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 1)" (paste)="onPaste($event, 1)">-->
<!--      <input #input2 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[2]"-->
<!--             [ngClass]="code[2]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 2)" (paste)="onPaste($event, 2)">-->
<!--      <input #input3 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[3]"-->
<!--             [ngClass]="code[3]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 3)" (paste)="onPaste($event, 3)">-->
<!--      <input #input4 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[4]"-->
<!--             [ngClass]="code[4]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 4)" (paste)="onPaste($event, 4)">-->
<!--      <input #input5 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[5]"-->
<!--             [ngClass]="code[5]?'border-blue-500':null"-->
<!--             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 5)" (paste)="onPaste($event, 5)">-->
<!--    </div>-->
<!--    <div class="col-12 flex justify-content-center align-items-center">-->
<!--      <p-button styleClass="mt-2 text-sm p-button-sm" label="OTP驗證" (click)="verify()"></p-button>-->
<!--    </div>-->
<!--    <ng-container *ngIf="verifyMethod===1">-->
<!--      <div class="col-12 flex justify-content-center align-items-center">-->
<!--        <p-button styleClass="text-xs p-button-sm p-button-info p-button-text"-->
<!--                  [label]="countdown ? countdown + ' 秒' :'重新寄送驗證碼'"-->
<!--                  [disabled]="idDisabled" [loading]="isCountdown" (onClick)="login()"></p-button>-->
<!--      </div>-->
<!--      <div class="col-12 text-center">-->
<!--        <span class="text-xs">MFA 驗證碼已寄送至：<span class="text-xs font-bold">{{ email }}</span></span>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngIf="verifyMethod===2">-->
<!--      <div class="col-12 text-center">-->
<!--        <span class="text-xs">請至認證應用程式獲取驗證碼</span>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--  <div class="col-12 flex align-items-center cursor-pointer h-3rem mt-2 hover:surface-50 border-round"-->
<!--       *ngIf="hasAnother" (click)="isAnother=true;isVerify=false">-->
<!--    <div class="justify-content-start pi pi-chevron-left"></div>-->
<!--    <div class="mx-auto">-->
<!--      使用其他驗證方式-->
<!--    </div>-->
<!--  </div>-->
<!--</p-dialog>-->

