<router-outlet></router-outlet>
<p-toast [breakpoints]="{'920px': {width: 'calc(100% - 20px)', left: '10px' ,right: '10px'}}"></p-toast>
<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-question text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button pButton label="取消" (click)="cd.reject()" class="p-button-danger p-button-outlined w-8rem "></button>
                <button pButton label="確認" (click)="cd.accept()" class="p-button-outlined w-8rem"></button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>
