// Injection token for the Http Interceptors multi-provider
import {HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {AuthService} from './services/auth.service';
import {inject} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {throwError, tap, take, retry, switchMap, filter} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {TokenStorageService} from "./services/token-storage.service";
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from "@abacritt/angularx-social-login";


const TOKEN_HEADER_KEY = 'Authorization'

export const tokenProvider: HttpInterceptorFn = (req, next) => {
  const storageServ = inject(TokenStorageService);
  const authServ = inject(AuthService);
  const token = storageServ.getToken();

  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `${token}`,
        'content-type': 'application/json'
      }
    })
  }
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && !req.url.includes('login')) {
          let isRefreshing = false; // 用於標記是否正在刷新 token
          let refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


          // 如果請求的 URL 包含 'refresh'，則登出
          if (req.url.includes('refresh')) {
            authServ.logout();
          }

          if (!isRefreshing) {
            isRefreshing = true;
            refreshTokenSubject.next(null);
            const token = storageServ.getRefreshToken();
            if (token) {
              // 如果存在 refresh token，則嘗試刷新 token
              return authServ.refreshToken(token).pipe(retry(3), tap(res => {
                  console.log('refreshToken', res) // 輸出刷新 token 的結果
                }),
                switchMap((token: any) => {
                  isRefreshing = false; // 將刷新狀態設為 false
                  storageServ.saveToken(token.body.access_token); // 儲存新的 access token
                  refreshTokenSubject.next(token.body.access_token); // 發送新的 access token
                  // 使用新的 access token 重新發送請求
                  return next(addTokenHeader(req, token.body.access_token)).pipe(tap());
                }),
                catchError((error) => {
                  isRefreshing = false; // 將刷新狀態設為 false
                  if (error.status === 401) {
                    authServ.logout(); // 如果刷新 token 失敗，則登出
                  }
                  console.log(error) // 輸出錯誤訊息
                  return throwError(() => error);
                })
              );
            } else {
              authServ.logout();
            }
          }
          return refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next(addTokenHeader(req, token))),
          );
        }
      }
      return throwError(() => err);
    })
  )
}


const addTokenHeader = (req: HttpRequest<unknown>, token: any) => {
  return req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, token)});
}

export const socialAuthProvider = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          '258489251155-2rh7p6hmgrhcs86un624d3fel9n7eh8i.apps.googleusercontent.com'
        )
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,

        provider: new FacebookLoginProvider('487140773878029')
      }
    ],
    onError: (error: any) => {
      console.error(error);
    }
  } as SocialAuthServiceConfig
}
