import {Component, OnInit} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {MessageService} from "primeng/api";
import {TokenStorageService} from "../../services/token-storage.service";
import {FacebookLoginProvider, GoogleSigninButtonModule, SocialAuthService} from "@abacritt/angularx-social-login";
import {CoolFacebookButtonComponent} from "@angular-cool/social-login-buttons";
// import {ReCaptchaV3Service, RecaptchaModule, RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
// import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [SharedModule, GoogleSigninButtonModule, CoolFacebookButtonComponent],
  providers: [
    // ReCaptchaV3Service,
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.recaptcha.siteKey
    // }
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  login_form: UntypedFormGroup;

  title: string = 'SaaS-AI任務追蹤與專案管理雲(ITS&PM)';

  loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authServ: AuthService,
    private tokenStorageServ: TokenStorageService,
    private messageServ: MessageService,
    private socialAuthServ: SocialAuthService
    // private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.login_form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', Validators.required],
      // recaptcha: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.socialAuthServ.authState.subscribe((user) => {
      this.openIdLogin('google', user)
    });
  }

  login() {
    this.loading = true;
    let body = this.login_form.value;
    // const v3key = environment.recaptcha.siteKey
    // this.recaptchaV3Service.execute('importantAction')
    // .subscribe((token: string) => {
    //   console.debug(`Token [${token}] generated`);
    // });

    if (this.login_form.valid) {
      this.authServ.login(body).subscribe({
        next: res => {
          console.log(res);
          this.tokenStorageServ.saveToken(res.body.access_token);
          this.tokenStorageServ.saveRefreshToken(res.body.refresh_token);
          this.tokenStorageServ.saveRole(res.body.role);
          this.tokenStorageServ.saveUser(res.body.resource_id);
          this.tokenStorageServ.saveUserName(res.body.name);

          this.router.navigate(['/projects']).then();
          this.messageServ.add({severity: 'success', summary: '歡迎', detail: '成功登入！'});
          this.loading = false;
        },
        error: err => {
          console.log(err);
          this.messageServ.add({severity: 'error', summary: '錯誤', detail: '登入失敗！'});
          this.loading = false;
        }
      })
    }
  }

  signInWithFB(): void {
    this.socialAuthServ.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.openIdLogin("facebook", user)
    });
  }

  openIdLogin(type: string, user: any) {
    let body = {}
    if (user.provider == "FACEBOOK") {
      body = {
        "name": user.name,
        "email": user.email,
      }
    } else if (user.provider == "GOOGLE") {
      body = {
        "email": user.email,
        "name": user.name,
        "idToken": user.idToken,
      }
    }

    console.log(user);
    console.log(type, body);
  }

  // 開啟HTML更多選項
  openLoginInfo(): void {
    const elements = document.querySelectorAll('.b-form, .box-form, .box-info');
    (elements[0] as HTMLElement).style.left = "-20%";
    (elements[1] as HTMLElement).style.left = "-20%";
    (elements[2] as HTMLElement).style.right = "-18%";
  }

  // 關閉HTML更多選項
  closeLoginInfo(): void {
    const elements = document.querySelectorAll('.b-form, .box-form, .box-info');
    (elements[0] as HTMLElement).style.left = "0%";
    (elements[1] as HTMLElement).style.left = "0%";
    (elements[2] as HTMLElement).style.right = "3%";
  }

  goToPasswordReset() {

  }
}
